import PageContainer from "layouts/components/pageContainer";
import { apiMethod } from "api/waitlist";
import { routes } from "routes";
import { useNavigate } from "react-router-dom";
import TableComponent from "components/table";
import {
    EyeOutlined,
} from "@ant-design/icons";
import {Button, Rate} from "antd";
import React from "react";
import moment from "moment";

const Waitlist = () => {
    const navigate = useNavigate();

    const columns = [
        {
            title: "Date",
            dataIndex: "createdAt",
            sorter: true,
            dataType: "text",
            render:(createdAt:any)=>{
                return createdAt ? moment(createdAt).format('MM/DD/YYYY HH:mm:ss') : '-';
            }
        },
        {
            title: "Name",
            dataIndex: "agentFullName",
            sorter: true,
            dataType: "text",
            render:(agentFullName:any,rest:any)=>{
                return  agentFullName || rest?.name ||  '-'
            }
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: false,
            dataType: "text",
            render:(email:any)=>{
                return  email ||  '-'
            }
        },
        {
            title: "User Type",
            dataIndex: "creatorEntityTypeId",
            sorter: false,
            dataType: "text",
            render:(creatorEntityTypeId:any,rest:any)=>{
                console.log(rest,"rest")
                return  creatorEntityTypeId ?  creatorEntityTypeId === "1" ? "Streamer" : 'Streamer Agency/Manager'  : rest?.brandEntityTypeId === 1 ?  "Brand" : 'Brand Agency'
            }
        },

        { title: "Actions", dataType: "actions", width: 96, render: (row: any) => {
                return (
                    <Button
                        type="text"
                        className="ant-btn-icon-only"
                        onClick={() => {
                            navigate(`${String(routes?.waitlist?.view?.path)?.replaceAll(":id", row.id)}`);
                        }}
                    >
                        <EyeOutlined />
                    </Button>
                )
            } },
    ];

    return (
        <PageContainer
            title="Waitlist"
        >
            <TableComponent
                apiMethod={apiMethod}
                apiKeyNames={{ list: "list" }}
                columns={columns}
                customAction={true}
                viewRoute={routes.waitlist.view}
                settingRoute={routes.waitlist.setting}
            />
        </PageContainer>
    );
};

export default Waitlist;
