import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, Row, Col, Rate } from "antd";
import PageHeader from "components/pageHeader";
import FormComponent from "components/form";
import { apiMethod } from "api/waitlist";
import { routes } from "routes";
import TextArea from "antd/es/input/TextArea";

const WaitlistManage = () => {
    const [data, setData] = useState<any>({});
    const { id } = useParams();
    const [form] = Form.useForm();

    return (
        <>
            <PageHeader
                title={data?.name || data?.agentFullName}
                subTitle={data?.brandEntityTypeId ? data?.brandEntityTypeId === 1 ? "Brand" : "Brand Agency" : data?.creatorEntityTypeId === "1" ? 'Streamer' : "Streamer Agency/Manager"}
            />
            <FormComponent
                routes={routes.waitlist}
                apiMethod={apiMethod}
                id={id}
                setData={setData}
                form={form}
                canSave={false}
                canNew={false}
            >
                <div className={`max-w-full p-5 category-form`}>
                    <Row gutter={16}>
                        {
                            data?.creatorEntityTypeId === "1" || data?.creatorEntityTypeId === "2" ?
                                <Col span={24} sm={12}>
                                    <Form.Item name={"agentFullName"} label={"Name"}>
                                        <Input autoComplete="off" disabled />
                                    </Form.Item>
                                </Col>
                                :
                                <Col span={24} sm={12}>
                                    <Form.Item name={"name"} label={"Name"}>
                                        <Input autoComplete="off" disabled />
                                    </Form.Item>
                                </Col>
                        }

                        <Col span={24} sm={12}>
                            <Form.Item name="email" label="Email">
                                <Input autoComplete="off" disabled />
                            </Form.Item>
                        </Col>
                        {
                            (data?.creatorEntityTypeId === "1" || data?.creatorEntityTypeId === 1) &&
                            <>
                                <Col span={24} sm={12}>
                                    <Form.Item name="streamerHandle" label={"Streamer Handle"}>
                                        <Input autoComplete="off" disabled />
                                    </Form.Item>
                                </Col>

                                <Col span={24} sm={12}>
                                    <Form.Item name="howLongHaveBeenStreaming" label={"How Long Have Been Streaming"}>
                                        <Input autoComplete="off" disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="aboutUs"
                                        label="How did you hear about us?"
                                    >
                                        <Input autoComplete="off" disabled />
                                    </Form.Item>
                                </Col>
                            </>

                        }

                        {
                            (data?.creatorEntityTypeId === "2" || data?.creatorEntityTypeId === 2) &&
                            <>
                                <Col span={24} sm={12}>
                                    <Form.Item name="companyName" label={"Company Name"}>
                                        <Input autoComplete="off" disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="aboutUs"
                                        label="How did you hear about us?"
                                    >
                                        <Input autoComplete="off" disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item name="streamersYouManage" label={"Managed Streamers"}>
                                        <TextArea autoComplete="off" disabled style={{ height: "auto" }} rows={4} />
                                    </Form.Item>
                                </Col>
                            </>

                        }

                        {
                            (data?.brandEntityTypeId === "1" || data?.brandEntityTypeId === 1) &&
                            <Col span={24} sm={12}>
                                <Form.Item name="brandEntityName" label={"Brand Name"}>
                                    <Input autoComplete="off" disabled />
                                </Form.Item>
                            </Col>
                        }
                        {
                            data?.brandEntityTypeId === "2" || data?.brandEntityTypeId === 2 &&
                            <Col span={24} sm={12}>
                                <Form.Item name="agencyName" label={"Agency Name"}>
                                    <Input autoComplete="off" disabled />
                                </Form.Item>
                            </Col>
                        }

                        {
                            (data?.brandEntityTypeId === 1 || data?.brandEntityTypeId === 2) &&
                            <Col span={24} sm={12}>
                                <Form.Item name="position" label={"Position"}>
                                    <Input autoComplete="off" disabled />
                                </Form.Item>
                            </Col>
                        }
                        {
                            data?.brandEntityTypeId === "2" || data?.brandEntityTypeId === 2 &&
                            <>
                                <Col span={24} sm={12}>
                                    <Form.Item name="agencyTypeName" label={"Typ of Agency"}>
                                        <Input autoComplete="off" disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item
                                        name="aboutUs"
                                        label="How did you hear about us?"
                                    >
                                        <Input autoComplete="off" disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={24} sm={12}>
                                    <Form.Item name="brandYouRepresent" label={"Brands Represented"}>
                                        <TextArea autoComplete="off" disabled style={{ height: "auto" }} rows={4} />
                                    </Form.Item>
                                </Col>
                            </>
                        }
                        {
                            (data?.brandEntityTypeId === 1) &&
                            <Col span={24} sm={12}>
                                <Form.Item
                                    name="aboutUs"
                                    label="How did you hear about us?"
                                >
                                    <Input autoComplete="off" disabled />
                                </Form.Item>
                            </Col>
                        }

                    </Row>
                </div>
            </FormComponent>

        </>
    );
};

export default WaitlistManage;
